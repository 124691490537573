var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "body",
    { staticClass: "h-vh-100 d-flex flex-justify-center flex-align-center" },
    [
      _c("div", { staticClass: "login-box" }, [
        _c(
          "form",
          {
            staticClass: "bg-white p-4 border-radius border-radius-12 border",
            attrs: {
              action: "javascript:",
              "data-role": "validator,Validator",
              "data-clear-invalid": "2000",
              "data-on-error-form": "invalidForm",
              "data-role-validator": "true",
              novalidate: "novalidate",
              autocomplete: "off",
            },
          },
          [
            _c("div", { staticClass: "text-center" }, [
              _c("img", {
                attrs: {
                  src: "/assets/img/brand/iperius_one_v6.svg",
                  width: "270",
                  alt: "Iperius One Logo",
                },
              }),
              _c("p", { staticClass: "text-muted h-auto" }, [
                _vm._v(_vm._s(_vm.$t("Sign in to start your session"))),
              ]),
            ]),
            _c("div", { staticClass: "form-group pt-4 pb-4" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model.trim",
                    value: _vm.userlogin.username,
                    expression: "userlogin.username",
                    modifiers: { trim: true },
                  },
                ],
                attrs: {
                  inputmode: "none",
                  "aria-autocomplete": "none",
                  autocomplete: "off",
                  "data-validate": "required",
                  "data-role": "input",
                  type: "text",
                  placeholder: _vm.$t("Username"),
                },
                domProps: { value: _vm.userlogin.username },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.$set(
                      _vm.userlogin,
                      "username",
                      $event.target.value.trim()
                    )
                  },
                  blur: function ($event) {
                    return _vm.$forceUpdate()
                  },
                },
              }),
              _c("span", { staticClass: "invalid_feedback" }, [
                _vm._v(_vm._s(_vm.$t("Please enter a valid username"))),
              ]),
            ]),
            _c("div", { staticClass: "form-group pb-4" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model.trim",
                    value: _vm.userlogin.password,
                    expression: "userlogin.password",
                    modifiers: { trim: true },
                  },
                ],
                attrs: {
                  autocomplete: "off",
                  "data-validate": "required",
                  "data-role": "input",
                  type: "password",
                  placeholder: _vm.$t("Password"),
                },
                domProps: { value: _vm.userlogin.password },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.$set(
                      _vm.userlogin,
                      "password",
                      $event.target.value.trim()
                    )
                  },
                  blur: function ($event) {
                    return _vm.$forceUpdate()
                  },
                },
              }),
              _c("span", { staticClass: "invalid_feedback" }, [
                _vm._v(_vm._s(_vm.$t("Please enter a valid password"))),
              ]),
            ]),
            _c(
              "div",
              {
                staticClass:
                  "form-group d-flex flex-align-center flex-justify-between",
              },
              [
                _c("label", { staticClass: "checkbox transition-on" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.userlogin.rememberme,
                        expression: "userlogin.rememberme",
                      },
                    ],
                    attrs: {
                      type: "checkbox",
                      "data-role": "checkbox",
                      "data-caption": _vm.$t("Remember for 15 days"),
                      "data-role-checkbox": "true",
                    },
                    domProps: {
                      checked: Array.isArray(_vm.userlogin.rememberme)
                        ? _vm._i(_vm.userlogin.rememberme, null) > -1
                        : _vm.userlogin.rememberme,
                    },
                    on: {
                      change: function ($event) {
                        var $$a = _vm.userlogin.rememberme,
                          $$el = $event.target,
                          $$c = $$el.checked ? true : false
                        if (Array.isArray($$a)) {
                          var $$v = null,
                            $$i = _vm._i($$a, $$v)
                          if ($$el.checked) {
                            $$i < 0 &&
                              _vm.$set(
                                _vm.userlogin,
                                "rememberme",
                                $$a.concat([$$v])
                              )
                          } else {
                            $$i > -1 &&
                              _vm.$set(
                                _vm.userlogin,
                                "rememberme",
                                $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                              )
                          }
                        } else {
                          _vm.$set(_vm.userlogin, "rememberme", $$c)
                        }
                      },
                    },
                  }),
                  _c("span", { staticClass: "check" }),
                  _c("span", { staticClass: "caption" }, [
                    _vm._v(_vm._s(_vm.$t("Remember for 15 days"))),
                  ]),
                ]),
              ]
            ),
            _c("div", { staticClass: "form-group text-center m-4" }, [
              _c(
                "button",
                {
                  staticClass: "button primary w-100",
                  on: {
                    click: function ($event) {
                      return _vm.login()
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("Log in")))]
              ),
            ]),
            _c(
              "div",
              {
                staticClass:
                  "form-group border-top bd-default pt-2 text-center",
              },
              [
                _c("a", { staticClass: "button", attrs: { href: "#" } }, [
                  _vm._v(_vm._s(_vm.$t("I forgot my password"))),
                ]),
                _c(
                  "a",
                  {
                    staticClass: "button flat-button",
                    attrs: {
                      href: "https://www.iperius.com/one",
                      target: "blank",
                    },
                  },
                  [_vm._v(_vm._s(_vm.$t("Buy an Iperius One License")))]
                ),
              ]
            ),
          ]
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }